.rules__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.rules__container>div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.rules__container>div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.rules__container>div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.rules__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  row-gap: 1rem;

}

.rules__details {
  display: flex;
  gap: 1rem;
}

.rules__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .rules__container {
    grid-template-columns: 1fr;
  }

  .rules__container>div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .rules__content {
    padding: 1rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .rules__container {
    gap: 1rem;
  }

  .rules__container>div {
    width: 100%;
    padding: 2rem 1rem;
  }
}