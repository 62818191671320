.order__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.order__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  display: grid;
  place-items: center;
}

.order__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: scale(1.8);
  transition: var(--transition);
}

.order__me-image:hover {
  transform: scale(1.9);
}

.order__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.order__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}

.order__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.order__icon {
  color: var(--color-primary);
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

.order__card h5 {
  font-size: 0.95rem;
}

.order__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.order__content p {
  margin-top: 2rem;
}

.order__content li,
.order__content p {
  margin-bottom: 1.5rem;
  color: var(--color-light);
  text-align: justify;
}

.li-icon {
  margin-right: 0.5rem;
}

.cheap {
  color: rgb(190, 190, 190);
  text-decoration: line-through;
}

.mobile {
  display: none;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .order__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .order__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .order__content p {
    margin: 1rem 0 1.5rem;
  }
}

#vedd-meg h3 {
  margin: 1rem auto;
}


/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  .order__me {
    width: 65%;
    margin: 0 auto 3rem;
  }

  .order__cards {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .order__content {
    text-align: center;
  }

  .order__content p {
    margin: 1.5rem 0;
  }

  .mobile {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: -2rem auto 1rem auto;
  }

}