header {
  height: 60rem;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* =============== CTA ============== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* =============== HEADER SOCIALS ============== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* =============== KILOVES ============== */
.header-img {
  width: 55rem;
  height: 36rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2rem;
  border-radius: 1rem;
  overflow: hidden;
}

.header-img img {
  -webkit-filter: drop-shadow(0 0 5px #c1c1c1);
  filter: drop-shadow(0 0 7px #c1c1c1);
}

/* =============== SCROLL DOWN ============== */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
  .header-img {
    width: 36rem;
    height: 36rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  header {
    padding-top: 4rem;
    height: 35rem;
  }
  .header-img {
    width: 24rem;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
  .cta {
    margin-top: 1.7rem;
    gap: 1rem;
  }
}